.App {
  text-align: center;
}

.custom-icon {
  /*border: 1px;
  border-style: solid;*/
  margin: 0;
}

.selected-page {
  color: #f9e344;
}

.unselected-page {
  color: white;
}

/* stuff from Beacon indicators */
.beaconIcon {
  position: unset !important;
  display: inline !important;
  margin: 0 !important;
}

.cluster-span {
  vertical-align: middle;
  line-height: normal;
}

.custom-marker-cluster {
  text-align: center;
  font-size: 12px !important;
  font-weight: bold;
  border-radius: 1em;
}

.custom-marker-cluster-green {
  background-color: #4BD28F;
}

.custom-marker-cluster-yellow {
  background-color: #FFC800;
}

.custom-marker-cluster-red {
  background-color: #D62828;
}

.aerialTypeMarker {
  position: fixed;
  left: 1pt;
  top: 2pt;
  z-index: 2;
  font-size: x-small;
  font-weight: bold;
  width: 18px;
  height: 18px;
  margin: auto;
}
